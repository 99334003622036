* {
  //font-family: 'Poppins', -apple-system, BlinkMacSystemFont, sans-serif !important;
  font-family: 'Poppins', serif !important;
}

body {
  padding: 0;
  margin: 0;
  background-color: #000;
  background-image: url("images/shimano-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 100vw;
  height: 100vh;
  color: #000;
}

#root {
  height: 100%;

  #alert-container {
    padding: 1rem;
  }

  #main-container {
    height: 100%;

    #navbar + #container {
      height: calc(100% - 160px) !important;
    }

    #container {
      height: calc(100% - 80px);
      align-items: center;

      .login-container {
        width: 100%;
        height: calc(100% - 50px);
        max-width: 550px;
        max-height: 650px;
        margin: 25px 0;
        padding: 35px 25px;
        background-color: rgba(255, 255, 255, .75);
        overflow: auto;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        .login-content {
          width: 100%;

          .login-logo {
            padding: 0 2rem 2rem 2rem;
            border-bottom: 1px solid #009cdf;
            margin-bottom: 2rem;
          }

          .login-title {
            font-size: 1rem;
            font-weight: 500;
            margin: 0;
            padding: 0 2rem;
            line-height: 1.4;
          }

          .login-text {
            font-size: 1rem;
            font-weight: 300;
            margin: 0;
            line-height: 1.4;
          }

          .login-form {
            margin: 2rem 0 0 0;

            .forgot-pw-link {
              text-align: left;
              margin: -.5rem 0 1rem 0;
            }
          }
        }

        @media (max-width: 960px) {
          margin: 12px;
          height: calc(100% - 24px);
          display: block;
        }
      }
    }
  }
}

.input-item {
  margin: 0 0 1rem 0 !important;

  .MuiInputBase-root {
    border-radius: .75rem !important;
    overflow: hidden !important;
    color: #8f8f8f !important;

    input {
      background-color: #fff !important;
      color: #000 !important;
    }

    fieldset {
      border: none !important;
    }
  }
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -12px) scale(.75) !important;
}

.button-item {
  background-color: #009ad9 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border-radius: 44px !important;
  font-size: 1rem !important;
  padding: .5rem 2.5rem !important;
  letter-spacing: 1px;

  &:hover {
    background-color: #00709f !important;
  }
}

[data-testid="ht-app"] {
  width: 100vw !important;
  height: calc(100vh - 160px) !important;
  margin-top: 80px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  overflow: hidden !important;

  > div {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    flex-direction: column-reverse !important;
    width: 100% !important;
    height: calc(100% - 1rem) !important;
    overflow: hidden !important;
    padding: 1rem !important;

    > div {
      position: static !important;
    }
  }
}

[ref="heres_bubble"] {
  &.heres_right {
    &.heres_hidden {
      display: none !important;
    }

    &.heres_visible {
      background-color: rgba(255, 255, 255, 0.61);
      color: #000;
      padding: 1rem 2rem;
      border-radius: 10px;
      box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.06);
      text-align: center;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.4;

      > #heres_bubble_close {
        display: none;
      }
    }
  }
}

[ref="heres_action"] {
  margin-top: .5rem;

  > #badge_notifications {
    position: relative;
    top: -60px;
    right: -38px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
  }
}

@media (max-width: 960px) {
  #navbar {
    //height: 90px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 10px;

    > div {
      justify-content: space-between;
      width: 100%;
      //margin-top: 5px;
    }
  }

  #footer {
    //height: 70px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0 10px;

    > span {
      font-size: .9rem;
    }
  }
}
