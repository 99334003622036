.MuiDialog-container
  .MuiFormLabel-root.MuiInputLabel-formControl.MuiInputLabel-outlined {
  top: 50%;
  -webkit-transform: translate(14px, -50%) scale(1);
  transform: translate(14px, -50%) scale(1);
}

.MuiDialog-container
  .MuiFormControl-root:focus-within
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  opacity: 0;
}

/* ASSISTENZA GARANZIA PRODOTTI PAGE */

/* BORDI BOX */
.MuiDialog-container.MuiDialog-scrollPaper .MuiPaper-rounded {
  border-radius: 12px;
}

/* LARGHEZZA BOX */
.MuiDialog-container.MuiDialog-scrollPaper .MuiDialog-paperFullWidth {
  width: 65%;
}

/* MARGIN BOTTOM */
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXl.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded {
  margin: 0 0 22px 0;
}

/* PULSANTE CLOSE  */
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
  position: relative;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper #simple-dialog-title {
  position: absolute;
  right: 16px;
  top: 24px;
  padding: 0;
}

#product-search {
  padding-left: 0;
  margin-bottom: 22px;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper
  #simple-dialog-title
  button {
  background-color: transparent;
  color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper
  #simple-dialog-title
  button::after {
  content: "\2715";
  position: absolute;
  top: 0;
  left: 0;
  color: #3db4e6;
  font-size: 22px;
  font-weight: bold;
  z-index: 999;
}

/* TITOLO H1 */
#product-search h1 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 3px;
}

/* TITOLO H2 */
#product-search h2.disclaimer {
  font-size: 15px;
  font-weight: normal;
  margin-top: 3px;
  margin-bottom: 3px;
}

/* INPUT SEARCH  */
.MuiInputBase-input:focus {
  outline: none;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXl.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded
  .MuiInputBase-root {
  border: 1.5px solid #2faee5;
  background-color: #e5f4fb;
  position: relative;
}

#product-search
  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXl.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded
  .MuiInputBase-root::after {
  content: "\26B2";
  position: absolute;
  top: 25%;
  right: 20px !important;
  font-size: 28px;
  transform: rotateZ(-40deg);
  color: #2faee5;
}

.MuiDialog-container .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px 40px 10px 14px;
}

/* PARAGRAFO PRODOTTI TROVATI */
#product-list > div:nth-child(1) > div:nth-child(1) > p:nth-child(1) {
  color: #808080;
  font-size: 16px;
}

/* ELENCO */
.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  position: relative;
  border-radius: 10px;
}

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button
  .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-center {
  color: #101212;
  margin-left: 50px;
}

#invoices-list div.tableInvoicesHeader {
  margin-top: 32px;
  margin-bottom: 3px;
}

#invoices-list div.tableInvoicesHeader div:first-child {
  margin-left: 64px;
}

#invoices-list div.tableInvoicesHeader div:nth-child(2) {
  margin-left: -25px;
}

#product-list div.tableProductsHeader {
  margin-top: 32px;
  margin-bottom: 3px;
}

#product-list div.tableProductsHeader div:first-child {
  /* colonna: codice prodotto */
  position: relative;
  left: 64px;
}

#product-list div.tableProductsHeader div:nth-child(2) {
  /* colonna: descrizione */
  position: relative;
  left: -25px;
}

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4.MuiGrid-grid-md-4 {
  color: #101212;
}

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button:nth-child(
    odd
  ) {
  background-color: #e5f4fb;
}

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button:nth-child(
    even
  ) {
  background-color: white;
}

hr.MuiDivider-root {
  display: none;
}

.MuiList-root .MuiButtonBase-root .MuiListItemIcon-root {
  display: none;
}

div.MuiGrid-root:nth-child(3) {
  position: relative;
  top: 0;
}

.MuiGrid-root b {
  display: none;
}

/* INTESTAZIONE TABELLA */
div.MuiButtonBase-root:nth-child(1) > div:nth-child(3) > div:nth-child(1) {
  position: relative;
}

/* LARGHEZZA COLONNE TABELLA */
/* COLONNA 1 */
ul.MuiList-root.MuiList-padding
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-3.MuiGrid-grid-md-3 {
  flex-grow: 0;
  max-width: 18%;
  flex-basis: 18%;
}

/* COLONNA 2 */
ul.MuiList-root.MuiList-padding
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-5.MuiGrid-grid-md-5 {
  max-width: 38%;
  flex-basis: 38%;
  padding-right: 10px;
}

ul.MuiList-root.MuiList-padding
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4.MuiGrid-grid-md-4 {
  max-width: 20%;
  flex-basis: 20%;
}

#product-list div.fakeButton {
  position: absolute;
  right: 44px;
  transform: translateY(-50%);
  background-color: #009ad9;
  padding: 10px 40px;
  color: white;
  border-radius: 5px;
  transition: opacity 0.3s;
}

#product-list
  .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button:hover::after {
  opacity: 0.8;
}

@media screen and (max-width: 1650px) {
  .MuiDialog-container.MuiDialog-scrollPaper .MuiDialog-paperFullWidth {
    width: 75%;
  }
}

@media screen and (max-width: 1500px) {
  .MuiDialog-container.MuiDialog-scrollPaper .MuiDialog-paperFullWidth {
    width: 85%;
  }
}

@media screen and (max-width: 1300px) {
  .MuiDialog-container.MuiDialog-scrollPaper .MuiDialog-paperFullWidth {
    width: 95%;
  }
}

@media screen and (max-width: 1090px) {
  .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button
    .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-center {
    margin-left: 10px;
  }

  #invoices-list div.tableInvoicesHeader div:first-child {
    margin-left: 25px;
  }

  #invoices-list div.tableInvoicesHeader div:nth-child(2) {
    margin-left: -15px;
  }

  #product-list div.tableProductsHeader div:first-child {
    /* colonna: codice prodotto */
    position: relative;
    left: 25px;
  }

  #product-list div.tableProductsHeader div:nth-child(2) {
    /* colonna: descrizione */
    position: relative;
    left: -30px;
  }

  #product-list div.tableProductsHeader div:nth-child(3) {
    /* colonna: brand */
    position: relative;
    left: 25px;
  }

  /* .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    font-size: 14px;
  } */
  #product-list
    .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button::after {
    right: 20px;
    padding: 8px 28px;
  }

  /* COLONNA 1 */
  ul.MuiList-root.MuiList-padding
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-3.MuiGrid-grid-md-3 {
    flex-grow: 0;
    max-width: 20%;
    flex-basis: 20%;
  }

  /* COLONNA 2 */
  ul.MuiList-root.MuiList-padding
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-5.MuiGrid-grid-md-5 {
    max-width: 41%;
    flex-basis: 41%;
  }

  ul.MuiList-root.MuiList-padding
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4.MuiGrid-grid-md-4 {
    max-width: 19%;
    flex-basis: 19%;
  }
}

@media screen and (max-width: 990px) {
  /* LENTE DI INGRANDIMENTO */
  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXl.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded
    .MuiInputBase-root::after {
    right: 10px;
  }

  /* MARGINE TABELLA */
  ul.MuiList-root.MuiList-padding {
    margin-top: 20px;
  }

  /* COLONNE TABELLA  */
  ul.MuiList-root.MuiList-padding
    .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-center {
    display: block;
  }

  .MuiGrid-root b {
    display: inline-block;
  }

  /* COLONNA 1 */
  ul.MuiList-root.MuiList-padding
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-3.MuiGrid-grid-md-3 {
    max-width: 100%;
  }

  /* COLONNA 2 */
  ul.MuiList-root.MuiList-padding
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-5.MuiGrid-grid-md-5 {
    max-width: 100%;
  }

  ul.MuiList-root.MuiList-padding
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4.MuiGrid-grid-md-4 {
    max-width: 100%;
  }

  /* BUTTON */
  .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    padding-bottom: 60px;
  }

  #product-list
    .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button::after {
    top: 75%;
    left: 26px;
    transform: translateY(-50%);
    background-color: #009ad9;
    color: white;
    display: inline-block;
    width: 140px;
    text-align: center;
    padding: 8px 0 !important;
    margin-top: 5px;
  }

  .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-center
    .MuiGrid-grid-sm-8 {
    max-width: 90%;
    flex-basis: 90%;
  }
}

@media screen and (max-width: 690px) {
  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper
    #simple-dialog-title
    button::after {
    left: 20px;
  }
}

@media screen and (max-width: 590px) {
  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper
    #simple-dialog-title
    button {
    position: relative;
    bottom: 10px;
  }

  .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-center
    .MuiGrid-grid-sm-8 {
    max-width: 100%;
    flex-basis: 100%;
  }

  #product-list
    .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button::after {
    margin-top: 10px;
  }
}

@media screen and (max-width: 490px) {
  #product-list
    .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button::after {
    margin-top: 15px;
  }
}

/* RESO PAGE */

/* TITOLO H1 */
#invoice-search > div:nth-child(1) > div:nth-child(1) > h1:nth-child(1) {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 3px;
}

/* TITOLO H2 */
#invoice-search h2.disclaimer {
  font-size: 15px;
  font-weight: normal;
  margin-top: 3px;
  margin-bottom: 3px;
}

/* MARGINE TRA INPUT E FATTURE TROVATE */
#invoice-search {
  padding-left: 0;
  margin-bottom: 22px;
}

#invoices-list > div:nth-child(1) > div:nth-child(1) > p:nth-child(1) {
  color: #808080;
  font-size: 16px;
}

/* BUTTON */
#invoices-list
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
  background-color: #009ad9;
  padding: 8px 95px;
  color: white;
  border-radius: 5px;
  transition: opacity 0.3s;
  text-transform: capitalize;
  box-shadow: none;
  font-size: 16px;
  letter-spacing: 1px;
}

#upload-image-button,
#choose-file-button {
  margin: 20px;
  transform: translateY(-50%);
  background-color: #009ad9;
  padding: 8px 95px;
  color: white;
  border-radius: 5px;
  transition: opacity 0.3s;
  text-transform: capitalize;
  box-shadow: none;
  font-size: 16px;
  letter-spacing: 1px;
}

#upload_image_name_label {
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
  clear: both;
}

#upload_content {
  text-align: center;
  padding: 10px;
}

#invoices-list
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary:hover,
#upload-image-button,
#choose-file-button {
  opacity: 0.8;
  box-shadow: none;
}

/* LARGHEZZA COLONNA */
#invoices-list .MuiGrid-grid-md-6 {
  flex-grow: 0;
  max-width: calc(100% / 3);
  flex-basis: calc(100% / 3);
}

#invoices-list .MuiGrid-grid-md-5 {
  flex-grow: 0;
  max-width: calc(100% / 3);
  flex-basis: calc(100% / 3);
}

#invoices-list .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4 {
  flex-grow: 0;
  max-width: calc(100% / 3);
  flex-basis: calc(100% / 3);
}

@media screen and (max-width: 990px) {
  #invoices-list .MuiGrid-container {
    flex-direction: column;
    align-items: flex-start;
  }

  #invoices-list
    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
    top: 50%;
    left: 0;
    transform: translateY(0);
    padding: 5px 25px;
    margin-top: 8px;
  }

  /* LARGHEZZA COLONNE */
  #invoices-list .MuiGrid-grid-md-6 {
    max-width: 100%;
    flex-basis: 100%;
  }

  #invoices-list .MuiGrid-grid-md-5 {
    max-width: 100%;
    flex-basis: 100%;
    margin-top: 5px;
  }

  #invoices-list .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4 {
    max-width: 100%;
    flex-basis: 100%;
  }
}

/* DEFINISCI RESO PRODOTTO PAGE  */

/* TITOLO H1 */
#invoice-products-list h1 {
  font-size: 20px;
  margin-top: 55px;
  margin-bottom: 15px;
}

/* TITOLO H2 */
#invoice-products-list h2.disclaimer {
  font-size: 15px;
  font-weight: normal;
  margin-top: 3px;
  margin-bottom: 3px;
}

#invoice-products-list .tableInvoiceHeader div {
  text-align: center;
  font-weight: bold;
}

#invoice-products-list .tableInvoiceHeader {
  margin-top: 15px;
}

#invoice-products-list .tableInvoiceHeader div:last-child {
  text-align: left;
}

#invoice-products-list > div:nth-child(1) > div:nth-child(1) > p:nth-child(2) {
  position: relative;
}

#invoice-products-list > div:nth-child(1) > div:nth-child(1) > p:nth-child(2) {
  display: inline-block;
  padding-left: 3px;
  font-size: 16px;
}

#invoice-products-list > div:nth-child(1) > div:nth-child(1) > p:nth-child(3) {
  display: inline-block;
  margin-left: 50px;
  font-size: 16px;
  position: relative;
  padding-left: 2px;
}

/* TORNA ALLE FATTURE */
#invoice-products-list {
  position: relative;
}

#invoice-products-list
  > div:nth-child(1)
  > div:nth-child(1)
  > button:nth-child(4) {
  content: "\21AA";
  position: absolute;
  top: -64px;
  left: 30px;
  width: 150px;
  color: #909090;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 10px 0;
}

#invoice-products-list
  > div:nth-child(1)
  > div:nth-child(1)
  > button:nth-child(4)::before {
  content: "\21AA";
  position: absolute;
  top: 0;
  left: -25px;
  font-size: 28px;
  color: #0ba0e0;
  transform: rotateZ(180deg);
}

#invoice-products-list
  > div:nth-child(1)
  > div:nth-child(1)
  > button:nth-child(4):hover::after {
  text-decoration: underline;
}

/* LARGHEZZA COLONNE */
#invoice-products-list .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
}

/* TABELLA */
#invoice-products-list .MuiList-root.MuiList-padding {
  margin-top: 5px;
}

#invoice-products-list
  .MuiList-root.MuiList-padding
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-4 {
  position: relative;
}

#invoice-products-list
  .MuiList-root.MuiList-padding
  li.MuiListItem-root:nth-child(odd) {
  background-color: #e5f4fb;
}

#invoice-products-list
  .MuiList-root.MuiList-padding
  li.MuiListItem-root
  > div:nth-child(2)
  > div:nth-child(3) {
  padding-left: 20px;
}

/* BUTTON */
#invoice-products-list .MuiDialogActions-root {
  justify-content: center;
}

#invoice-products-list button.MuiButtonBase-root:nth-child(1) {
  width: 130px;
  height: 34px;
  margin-right: 10px;
  padding: 0;
  border-radius: 20px;
  background-color: #e5f4fb;
  color: transparent;
  color: #009cdf;
  text-transform: capitalize;
  font-size: 16px;
  position: relative;
  transition: opacity 0.3s;
}

#invoice-products-list button.MuiButtonBase-root:nth-child(1):hover::after {
  opacity: 0.8;
}

#invoice-products-list button.MuiButtonBase-root:nth-child(2) {
  width: 130px;
  margin-left: 10px;
  padding: 3px 0;
  border-radius: 20px;
  background-color: #009cdf;
  color: white;
  text-transform: capitalize;
  font-size: 16px;
  transition: opacity 0.3s;
  position: relative;
}

#invoice-products-list button.MuiButtonBase-root:nth-child(2):hover {
  opacity: 0.8;
}

#invoice-products-list .MuiDialogActions-root.MuiDialogActions-spacing {
  margin: 20px 0 15px 0;
}

/* SELECT QUANTITA' DA RESTITUIRE */
#invoice-products-list .MuiInput-underline::before {
  border-bottom: 0;
}

#invoice-products-list .MuiInput-underline::after {
  border-bottom: 2px solid #009cdf;
}

#invoice-products-list
  li.MuiListItem-root
  > div:nth-child(2)
  > div:nth-child(4)
  > div:nth-child(2) {
  background-color: white;
  border: 1px solid #d8e0e3;
}

#invoice-products-list
  li.MuiListItem-root
  > div:nth-child(2)
  > div:nth-child(4)
  > div:nth-child(2)
  > select:nth-child(1) {
  padding-left: 5px;
}

#invoice-products-list
  .MuiList-root.MuiList-padding
  li.MuiListItem-root
  > div:nth-child(2)
  > div:nth-child(3) {
  color: black;
}

@media screen and (max-width: 990px) {
  /* other... */
  #invoice-search > div:nth-child(1) > div:nth-child(1) > h1:nth-child(1) {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 3px;
  }

  #invoice-products-list h1 {
    font-size: 18px;
  }

  #invoice-products-list .tableInvoiceHeader {
    display: none;
  }

  #invoices-list div.tableInvoicesHeader {
    display: none;
  }

  #product-list div.tableProductsHeader {
    display: none;
  }

  #invoice-products-list
    .MuiList-root.MuiList-padding
    li.MuiListItem-root
    > div:nth-child(2)
    > div:nth-child(3) {
    padding-left: 0;
  }

  #invoice-products-list .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 {
    max-width: 100%;
  }

  #invoice-products-list
    > div:nth-child(1)
    > div:nth-child(1)
    > p:nth-child(3) {
    display: block;
    margin-top: 5px;
    margin-left: 0;
    font-size: 16px;
    position: relative;
    padding-left: 2px;
  }

  #invoice-products-list .MuiList-root.MuiList-padding {
    margin-top: 5px;
  }

  #invoice-products-list ul li.MuiListItem-root.MuiListItem-gutters {
    border-radius: 12px;
  }

  #invoice-products-list .MuiListItemIcon-root {
    display: none;
  }

  #invoice-products-list
    li.MuiListItem-root
    > div:nth-child(2)
    > div:nth-child(4)
    > b:nth-child(1) {
    display: block;
  }

  #invoice-products-list
    .MuiList-root.MuiList-padding
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-4 {
    margin: 3px 0;
  }

  #invoice-products-list
    .MuiList-root.MuiList-padding
    li.MuiListItem-root
    > div:nth-child(2)
    > div:nth-child(3) {
    margin: 3px 0;
  }
}

@media screen and (max-width: 790px) {
  .MuiPaper-root.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-12.MuiPaper-elevation0 {
    padding: 10px 25px;
    height: 110px;
  }

  .MuiPaper-root.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-12.MuiPaper-elevation0
    .jss2 {
    flex-direction: column;
  }

  .MuiPaper-root.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.MuiPaper-elevation6 {
    justify-content: center;
    margin: 0;
  }

  .MuiPaper-elevation0 .jss2 #topbar-right-side {
    justify-content: space-between;
    margin-top: 10px;
  }

  #root::after {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    width: 300px;
    text-align: center;
  }

  body::after {
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
  }

  .MuiBox-root.jss11 {
    padding: 0;
  }

  .heres_visible.heres_right {
    transform: inherit !important;
  }

  .heres_visible.heres_right.svelte-17q26mu {
    bottom: 120px;
  }

  .heres_visible.heres_right[data-testid="ht-bubble"] {
    bottom: 235px;
    width: calc(100% - 50px);
    transform: translateX(-50%) !important;
  }

  .heres_visible.heres_right[data-testid="ht-bubble"] > #heres_bubble_close {
    display: none;
  }
}

@media screen and (max-width: 510px) {
  .MuiPaper-root.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.MuiPaper-elevation6
    .jss4 {
    margin: 0 15px;
  }

  .MuiTypography-subtitle1 {
    font-size: 14px !important;
  }
}

@media screen and (max-height: 730px) {
  .MuiPaper-root.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4.MuiGrid-grid-md-4.MuiPaper-elevation6 {
    display: flex;
    overflow: scroll;
    padding-top: 20px;
    padding-bottom: 40px;
    align-items: flex-start;
    width: 100%;
    height: calc(100vh - 75px);
  }

  .jss6 {
    margin-top: 0 !important;
  }
}
